import * as React from 'react';

import pga from '../../images/DFH-PGAT.png';
import ncsu from '../../images/DFH-NCSU.png';
import jag from '../../images/DFH-JAGS.png';
import uf from '../../images/DFH-UF.png';

const Sponsorships: React.FC = () => {
	return (
		<section className={'my-20 px-5'}>
			<div className={'container mx-auto flex flex-col gap-4'}>
				<h2 className="m-0 pb-8 text-center font-kiona text-4xl font-normal uppercase text-secondary max-sm:text-3xl">
					National Partnerships
				</h2>
				<img
					src={pga}
					width={239}
					alt="PGA Tour"
					className="mx-auto w-auto max-w-[631px] max-md:max-h-[110px]"
				/>
				<h2 className="m-0 pb-16 pt-20 text-center font-kiona text-4xl font-normal uppercase text-secondary max-md:pb-10 max-sm:text-3xl">
					Regional Partnerships
				</h2>
				<div className="flex flex-wrap justify-center gap-x-24 gap-y-12">
					<img
						src={jag}
						alt="JAGS"
						width={355}
						className="mx-auto max-h-[110px] w-auto"
					/>
					<img
						src={uf}
						alt="UF"
						width={450}
						className="mx-auto max-h-[110px] w-auto"
					/>
					<img
						src={ncsu}
						alt="NCSU"
						width={315}
						className="mx-auto max-h-[110px] w-auto"
					/>
				</div>
			</div>
		</section>
	);
};

export default Sponsorships;
